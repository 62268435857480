// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { catalogoScripts } from "../../../scripts/catalogo"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Buscar producto o servicio</h2>
                <p>
                El catálogo todal del SAT es demasiado extenso para entregarse completo con un solo query. <br />
                </p>
                <p>
                La forma de usar esta ruta de catálogo de productos y servicios es una comparación de palabras en la que recibimos un campo: <code>compare: String</code> donde puedes agregar un texto para comparar entre la descripción y palabras similares de cada elemento.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/sat/productosServicios/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>compare: </b> Texto libre para comparar las entradas del catálogo contra este valor.
                        </p>
                    </li>
                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>clave: </b> La clave a usar como producto o servicio en tu concepto.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>descripcion:</b> Nombre dado a la clave de producto o servicio en el catálogo.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>trasladoIVA:</b> Se espera usar Traslado de IVA con esta clave (solo sugerencia, no obligatorio).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>trasladoIEPS:</b> Se espera usar Traslado de IEPS con esta clave (solo sugerencia, no obligatorio).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>complemento:</b> Se espera usar en complementos (solo sugerencia, no obligatorio).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>palabrasSimilares:</b> Palabras similares que incluye el catálogo para esta clave.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>match:</b> Certeza de la respuesta, que tan hipotéticamente seguro es que esta clave es la deseada.
                        </p>
                    </li>
                </ul>
                <p>
                    Es importante saber que los valores de trasladoIVA, trasladoIEPS y complemento son solo sugerencias que registra el SAT pero no tienen un valor real ni deden tomarse muy en cuenta. 
                    Se exponen pues algunos clientes tendrán con contadores que pueden tener preferencia en este uso.
                </p>
                <SectionNavigate next={`/api/catalogo/catalogo-claveunidad`} previous={`/api/catalogo/catalogo-formaspago`}/>
            </div>
            <div className="side">
                <CodeBlock title="Productos o servicios" requests={catalogoScripts.productoservicio.requests} response={catalogoScripts.productoservicio.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
